gridster {
  background: #F9F9F9 !important;

  &.fit {
    overflow: visible !important;
  }

  &-item {
    background: transparent !important;
    overflow: visible !important;
    border-radius: 4px !important;
  }
}

.instruction {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  z-index: 1000;

  span {
    z-index: 666;
  }

  &.absolute {
    position: absolute;
  }

  strong {
    padding: 0 4px;
    color: #101010;
  }

  .content {
    position: relative;
    font-size: 18px;
  }
}

$border-width: 0px;

.cell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 42px;
  transition: all 0.2s ease-out;

  border: none;

  cursor: pointer;
  user-select: none;
  border-radius: 4px;

  &.highlighted {
    border: 3px dashed #ee4822;
    font-weight: bold;
  }

  &.selected {
    color: transparent !important;
    border-color: rgba(238, 72, 34, 0.42) !important;
    background: #bbbcbf !important;
    cursor: default;
  }
}
